import React, { memo, useCallback, useMemo } from "react"

import { useArticles } from "../../hooks/useArticles"
import { useRoutes } from "../../hooks/useRoutes"
import { useTemplate } from "../../hooks/useTemplate"

export const withArticle = Component =>
  memo(({ name = "Article", articles, location, page, template }: any) => {
    const { articleNormaliser, shareArticle, useArticlesByCategory } = useArticles(location)
    const { linkResolver } = useRoutes()
    const { global } = useTemplate()

    const allArticles = useArticlesByCategory()
    const locales = useMemo(
      () => ({
        ...global,
        ...articles,
        ...template,
      }),
      [articles, global, template]
    )
    const item = articleNormaliser(page, true)
    const breadcrumbs = useMemo(
      () => [linkResolver({ ...articles, title: articles?.metadata?.title || articles?.title }, `articles`)],
      [articles, linkResolver]
    )
    const currentPosition = allArticles?.findIndex(({ id }) => id === item?.id)
    const pagination = useMemo(
      () => ({
        previous: articleNormaliser(allArticles?.[currentPosition - 1]),
        next: articleNormaliser(allArticles?.[currentPosition + 1]),
      }),
      [allArticles, articleNormaliser, currentPosition]
    )
    const related = useMemo(() => page?.related || allArticles?.filter(({ id }) => id !== item?.id), [allArticles, item, page])
    const products = useMemo(() => ({ id: `related-${item?.slug?.current}`, collection: item?.collection, products: item?.products }), [item])

    const handleShare = useCallback(network => shareArticle(network, item), [item, shareArticle])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          article={item}
          breadcrumbs={breadcrumbs}
          handleShare={handleShare}
          locales={locales}
          location={location}
          pagination={pagination}
          products={products}
          related={related}
        />
      ),
      [item, breadcrumbs, handleShare, locales, location, pagination, products, related]
    )
  })
