import tw from "twin.macro"

import { StyledContainer } from "../Styled/Container"
import { StyledLink } from "../Styled/Button"
import { StyledRow } from "../Styled/Row"
import { H2, H4, Overline, P } from "../Styled/Text"

export const Page = tw.article`py-12 md:pt-26.25 md:pb-8`
export const Category = tw(Overline)`text-left md:text-center mt-6 md:mt-16 mb-6 md:mb-8`
export const Title = tw(H2)`text-3xl`
export const Subtitle = tw(H4)`text-xl`
export const Date = tw(P)`font-normal md:font-medium md:text-xl mb-6 md:mb-12`
export const Pagination = tw(StyledContainer)`mt-8 md:mt-16`
export const PaginationText = tw(P)`font-bold text-xl first:mr-2 last:ml-2`
export const Link = tw(StyledLink)`block mx-2`
export const Sharing = tw(StyledRow)``
export const Share = tw(P)`flex-shrink-0 mx-2`
