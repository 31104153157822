import React from "react"
import { graphql } from "gatsby"

import { Article as Template } from "../components/Article/Article"

export const query = graphql`
  query ($id: String!) {
    page: sanityArticle(_id: { eq: $id }) {
      ...GatsbyArticleFragment
      content: _rawContent(resolveReferences: { maxDepth: 6 })
      related: _rawRelated(resolveReferences: { maxDepth: 4 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
    articles: sanityPageArticles {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalAll
      title
    }
    template: sanityTemplateArticle {
      additionalPrevious
      additionalNext
      additionalShare
      additionalProducts
      additionalRelated
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Template {...props} {...data} />

export default Component
