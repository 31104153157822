import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withArticle } from "./withArticle"
import { Icon } from "../Icon/Icon"
import { Sections } from "../Sections/Sections"
import { FeaturedArticles } from "../Sections/FeaturedArticles/FeaturedArticles"
import { FeaturedCollection } from "../Sections/FeaturedCollection/FeaturedCollection"
import { ArticleListHeader } from "./List/Header/ArticleListHeader"
import { StyledLink } from "../Styled/Button"
import { StyledColumn } from "../Styled/Column"
import { StyledContainer } from "../Styled/Container"
import { StyledImage } from "../Styled/Image"
import { StyledRow } from "../Styled/Row"
import { Page, Title, Subtitle, Date, Category, Pagination, PaginationText, Sharing, Share, Link } from "./styledArticle"

export const Article = withArticle(
  ({ article, breadcrumbs, handleShare, locales, location, pagination, products, related }): JSX.Element => (
    <Page>
      <ArticleListHeader breadcrumbs={breadcrumbs} location={location} page={{ ...locales, ...article }} title={locales?.title} />
      <StyledContainer width={`xlg`}>
        <StyledImage alt={article?.image?.alt} fluid={{ ...article?.image, aspectRatio: 41 / 26 }} />
      </StyledContainer>
      <StyledContainer width={`lg`}>
        {article?.category && (
          <Category colour={article?.category?.colour} large>
            {article?.category?.title}
          </Category>
        )}
        <Title as={`h1`} withSpacing={`xxs`}>
          {article?.title}
        </Title>
        {article?.date && <Date colour={`grey-darker`}>{article?.date}</Date>}
        {article?.description && (
          <Subtitle colour={`grey-darker`} withSpacing={`xs`}>
            {article?.description}
          </Subtitle>
        )}
      </StyledContainer>
      <Sections page={article} />
      <div>
        <Pagination as={`section`} width={`xlg`}>
          <StyledRow
            gutter={`sm`}
            items={`center`}
            withSpacing={products?.products?.length > 0 || products?.collection?.shopify?.shopifyHandle || related?.length > 0 ? `sm` : null}
          >
            <StyledColumn gutter={`sm`} width={`no-shrink`}>
              {pagination?.previous?.link?.url && (
                <StyledLink as={GatsbyLink} colour={`grey-darker`} title={locales?.additionalPrevious} to={pagination?.previous?.link?.url}>
                  <StyledRow items={`center`}>
                    <Icon name={`left`} title={locales?.additionalPrevious} width={12} />
                    <PaginationText>{locales?.additionalPrevious}</PaginationText>
                  </StyledRow>
                </StyledLink>
              )}
            </StyledColumn>
            <StyledColumn gutter={`sm`} width={`grow`}>
              <Sharing items={`center`} justify={`center`} withWrap>
                <Share colour={`grey-darker`}>{locales?.additionalShare}</Share>
                <Link colour={`grey-darker`} onClick={() => handleShare(`email`)} title={locales?.additionalEmail}>
                  <Icon name={`email`} height={20} width={20} />
                </Link>
                <Link colour={`grey-darker`} onClick={() => handleShare(`facebook`)} title={locales?.additionalFacebook}>
                  <Icon name={`social-facebook`} height={24} width={24} />
                </Link>
                <Link colour={`grey-darker`} onClick={() => handleShare(`pinterest`)} title={locales?.additionalPinterest}>
                  <Icon name={`social-pinterest`} height={24} width={24} />
                </Link>
              </Sharing>
            </StyledColumn>
            <StyledColumn gutter={`sm`} width={`no-shrink`}>
              {pagination?.next?.link?.url && (
                <StyledLink as={GatsbyLink} colour={`grey-darker`} title={locales?.additionalNext} to={pagination?.next?.link?.url}>
                  <StyledRow items={`center`}>
                    <PaginationText>{locales?.additionalNext}</PaginationText>
                    <Icon name={`right`} title={locales?.additionalNext} width={12} />
                  </StyledRow>
                </StyledLink>
              )}
            </StyledColumn>
          </StyledRow>
        </Pagination>
      </div>
      {products?.products?.length > 0 || products?.collection?.shopify?.shopifyHandle ? (
        <FeaturedCollection {...products} title={locales?.additionalProducts} />
      ) : null}
      {related?.length > 0 ? <FeaturedArticles articles={related} condensed limit={2} title={locales?.additionalRelated} /> : null}
    </Page>
  )
)
